<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>合同管理</el-breadcrumb-item>
      <el-breadcrumb-item>供应商信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div style="display: flex">
        <el-button style="margin-right: 15px" type="primary" @click="opentan()">添加信息</el-button>
        <el-input style="width: 300px" placeholder="请输入内容" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="sosuofun()"></el-button>
        </el-input>
      </div>

      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%   text-align: center">
        <el-table-column align="center" prop="no" label="供应商编号" width="120"></el-table-column>
        <el-table-column align="center" prop="supplier" label="供应商名称" width="150"></el-table-column>
        <el-table-column align="center" prop="type_name" label="供应货物类型"></el-table-column>
        <el-table-column align="center" prop="lv" label="供应商等级"></el-table-column>
        <el-table-column align="center" prop="cooperate_count" label="累计合作次数"></el-table-column>
        <el-table-column align="center" prop="contract_amount" label="累计合同金额"></el-table-column>
        <el-table-column align="center" prop="contract_amount" label="未结账款金额"></el-table-column>
        <el-table-column align="center" prop="overtime_amount" label="逾期账款金额"></el-table-column>
        <el-table-column align="center" prop="dang" label="当月应结合同金额"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row.id)"></el-button>
            <!-- <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />

      <!-- 添加弹窗 -->
      <el-dialog title="供应商详细信息" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
        <!-- 供应商名称 -->
        <el-form ref="ruleFrom" :model="ruleFrom" label-width="100px" :rules="drules">
          <el-row type="flex" class="row-bg" justify="space-around ">
            <el-col :span="8">
              <el-form-item label="供应商名称" prop="supplier">
                <div class="el-select"><el-input v-model="ruleFrom.supplier" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>
            <!--供应商简称-->
            <el-col :span="8">
              <el-form-item label="供应商简称" >
                <div class="el-select"><el-input v-model="ruleFrom.simple_name" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 供应商联系人 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="联系人">
                <div class="el-select"><el-input v-model="ruleFrom.linkman" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>

            <!--供应商货物类型-->
            <el-col :span="8">
              <el-form-item label="供应商类型" prop="type">
                <el-select v-model="ruleFrom.type" multiple    collapse-tags>
                  <el-option v-for="item in trashtypeData" :key="item.id" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- {{ruleFrom.type}} -->
          </el-row>

          <!-- 供应商地区 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="供应商地址" prop="area">
                <el-cascader ref="tree" @change="optpidfun" v-model="ruleFrom.area" :options="usetypelist" clearable :props="casprops"></el-cascader>
              </el-form-item>
            </el-col>

            <!--供应商联系方式-->
            <el-col :span="8">
              <el-form-item label="联系方式" >
                <el-input type="text" v-model="ruleFrom.contact" placeholder="客户联系方式"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 供应商固定电话 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="固定电话" >
                <el-input type="text" v-model="ruleFrom.contact_phone" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <!-- 供应商详细地址 -->
            <el-col :span="8">
              <el-form-item label="详细地址" >
                <el-input type="text" v-model="ruleFrom.contact_address" placeholder="例**街道**路**号**楼**号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <!-- 业务负责人 -->
            <el-col :span="8">
              <el-form-item label="业务负责人" >
                <el-autocomplete
                  :trigger-on-focus="false"
                  placeholder="请输入内容"
                  v-model="ruleFrom.principal_users"
                  :fetch-suggestions="validateCounts"
                  value-key="name"
                  @select="handleSelect($event, '负责人')"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
            <el-form-item label="开户行" >
              <el-input type="text" v-model="ruleFrom.billing_bank" placeholder="请输入开户行"></el-input>
            </el-form-item>

            <el-form-item label="开户银行卡号" >
              <el-input type="number" v-model="ruleFrom.billing_cardno"  placeholder="请输入开户银行卡号"></el-input>
            </el-form-item>

            <el-form-item label="税号" >
              <el-input type="number" v-model="ruleFrom.tax_no" placeholder="请输入税号"></el-input>
            </el-form-item>

          <el-row type="flex" class="row-bg" justify="center">
            <el-form-item>
              <el-button @click="cancelfun()">取 消</el-button>
              <el-button type="primary" @click="submitForm('ruleFrom')">添加</el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 修改弹窗 -->
      <el-dialog title="供应商详细信息" :visible.sync="enddialogVisible" width="50%">
        <!-- 供应商名称 -->
        <el-form ref="Detail" :model="Detail" label-width="100px" :rules="drules">
          <el-row type="flex" class="row-bg" justify="space-around ">
            <el-col :span="8">
              <el-form-item label="供应商名称" prop="supplier">
                <div class="el-select"><el-input v-model="Detail.supplier" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>
            <!--供应商简称-->
            <el-col :span="8">
              <el-form-item label="供应商简称" >
                <div class="el-select"><el-input v-model="Detail.simple_name" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 供应商联系人 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="联系人" >
                <div class="el-select"><el-input v-model="Detail.linkman" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>

            <!--供应商货物类型-->
            <el-col :span="8">
              <el-form-item label="供应商类型" prop="type">
                <el-select v-model="Detail.type"  multiple  collapse-tags>
                  <el-option v-for="item in trashtypeData" :key="item.id" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 供应商地区 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="供应商地址" prop="area">
                <el-cascader ref="tree" @change="optpidfuns" v-model="Detail.area" :options="usetypelist" clearable :props="casprops"></el-cascader>
              </el-form-item>
            </el-col>

            <!--供应商联系方式-->
            <el-col :span="8">
              <el-form-item label="联系方式" >
                <el-input type="text" v-model="Detail.contact" placeholder="客户联系方式"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 供应商固定电话 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="固定电话" >
                <el-input type="text" v-model="Detail.contact_phone" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <!-- 供应商详细地址 -->
            <el-col :span="8">
              <el-form-item label="详细地址">
                <el-input type="text" v-model="Detail.contact_address" placeholder="例**街道**路**号**楼**号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <!-- 业务负责人 -->
            <el-col :span="8">
              <el-form-item label="业务负责人" >
                <el-autocomplete
                  :trigger-on-focus="false"
                  placeholder="请输入内容"
                  v-model="Detail.principal_users"
                  :fetch-suggestions="endvalidateCounts"
                  value-key="name"
                  @select="handleSelect($event, '负责人')"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>

           <el-form-item label="开户行" >
              <el-input type="text" v-model="Detail.billing_bank" placeholder="请输入开户行"></el-input>
            </el-form-item>

            <el-form-item label="开户银行卡号" >
              <el-input type="number" v-model="Detail.billing_cardno"  placeholder="请输入开户银行卡号"></el-input>
            </el-form-item>

            <el-form-item label="税号" >
              <el-input type="number" v-model="Detail.tax_no" placeholder="请输入税号"></el-input>
            </el-form-item>

          <el-row type="flex" class="row-bg" justify="center">
            <el-form-item>
              <el-button @click="cancelfuns()">取 消</el-button>
              <el-button type="primary" @click="amend('Detail')">修改</el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { Area } from '../../../api/pact'
import { Type, Drop, Getlist, Addsupplier, Detailsgong, EndCompile } from '../../../api/slogong'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      ruleFrom: {
        supplier: '',        // 供应商名称
        simple_name: '',     // 简称
        type: [],            // 类型
        linkman: '',         // 联系人
        contact: '',         // 联系方式
        area: '',            // 地区
        contact_address: '', // 详细地址
        contact_phone: '',   // 座机
        principal_user: '',  // 业务负责人ID
        principal_users: '', // 负责人
        billing_bank:'',     // 开户行
        billing_cardno:'',   // 开会银行卡好
        tax_no:'',           // 税号
      },
      usetypelist: [],       // 地区
      casprops: { value: 'code', label: 'area_name', checkStrictly: false, children: 'list' }, // 地区级联选择的配置
      tableData: [],         // 列表假数据
      dialogVisible: false,  // 弹窗
      trashtypeData: '',     // 供应商类型
      userlist: [],          // 业务负责人
      enddialogVisible: false,
      // 表单验证规则
      drules: {
        contact_phone: [{ required: true, message: '请输入正确的格式', trigger: 'blue' }],
        type: [{ required: true, message: '请选择供应商类型', trigger: 'change' }],
        supplier: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, message: '请填写正确格式', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        linkman: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          { min: 2, max: 5, message: '请填写正确格式', trigger: 'blur' }
        ],
        area: [{ required: true, message: '请选择地址', trigger: 'blur' }],
        contact_address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          { min: 2, message: '请填写详细地址', trigger: 'blur' }
        ],
        simple_name: [
          { required: true, message: '请输入公司简称', trigger: 'blur' },
          { min: 2, message: '请填写详细地址', trigger: 'blur' }
        ],
        principal_users: [
          { required: true, message: '请输入业务负责人', trigger: 'blur' },
          { min: 1, message: '请填写详细地址', trigger: 'blur' }
        ]
      },
      pageData: {
        page: 1,          // 当前页数
        size: 10,         // 当前页数上面的数量
        count: 0          // 共有多少条
      },
      // 供应商详情信息
      Detail: {
        supplier_id: '',
        supplier: '',           // 供应商名称
        simple_name: '',        // 简称
        type: [],               // 类型
        linkman: '',            // 联系人
        contact: '',            // 联系方式
        area: '',               // 地区
        contact_address: '',    // 详细地址
        contact_phone: '',      // 座机
        principal_user: '',     // 业务负责人ID
        principal_users: '',    // 负责人
        billing_bank:'',        // 开户行
        billing_cardno:'',      // 开户银行卡号
        tax_no:'',              // 税号
      }
    }
  },
  mounted() {
    this.Getlists()
  },
  methods: {
      sosuofun(){

    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 成功
          this.Addsuppliers(this.ruleFrom) // 添加用户信息
          this.Getlists()                  // 添加成功之后重新拉取列表
          this.dialogVisible = false
          this.$refs[formName].resetFields()
        }
      })
    },
    // 弹窗
    opentan() {
      this.dialogVisible = true
      this.Areas()  // 拉取地区联动
      this.Types()  // 客户类型下拉框
    },
    // 添加取消
    cancelfun() {
      this.dialogVisible = false
      // this.Getlists()
      this.$refs.ruleFrom.resetFields()
      this.ruleFrom.simple_name= '',     // 简称
      this.ruleFrom.linkman= '',         // 联系人
      this.ruleFrom.contact= '',         // 联系方式
      this.ruleFrom.contact_address= '', // 详细地址
      this.ruleFrom.contact_phone= '',   // 座机
      this.ruleFrom.principal_user= '',  // 业务负责人ID
      this.ruleFrom.principal_users= ''  // 负责人
      this.ruleFrom.billing_bank = ''    // 开户行
      this.ruleFrom.billing_cardno = ''  // 开户银行账号
      this.ruleFrom.tax_no = ''          // 税号

    },
    // 修改取消
    cancelfuns() {
      this.enddialogVisible = false
    },
    // 获取地区编码
    optpidfun(e) {
      this.ruleFrom.area = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
      // console.log(this.ruleFrom.area)
    },
    // 修改地区编码
    optpidfuns(e) {
      this.Detail.area = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
      // console.log(this.Detail.area)
    },
    // 搜索负责人后的数据
    validateCounts(queryString, cb) {
      // 调用搜索方法
      this.Drops(queryString, cb)
    },
    // 选中
    handleSelect(e, term) {
      this.ruleFrom.principal_user = e.value    // 选中负责人的id
      this.Detail.principal_user = e.value      // 修改后负责人的id
    },
    // 修改数据的选中
    endvalidateCounts(queryString, cb) {
      // console.log(queryString, cb)
      this.Drops(queryString, cb)
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getlists()
    },
    bianye(newPape) { 
      this.pageData.page = newPape  // 分页页数变化
      this.Getlists()               // 获取列表
    },
    // 关闭添加弹窗
    handleClose() {
      this.dialogVisible = false
      this.cancelfun()
    },
    // 查看客户信息
    editfun(ev) {
      console.log(ev);
      this.Detail.supplier_id = ev
      this.Detailsgongs(ev)        // 点击修改按钮 拉取客户详情
      this.enddialogVisible = true // 修改信息弹窗
      this.Types()                 // 客户类型下拉框
      this.Areas()                 // 拉取地区联动
    },
    // 确认修改
    amend(Detail) {
      this.$refs[Detail].validate(valid => {
        if (valid) {
          this.EndCompiles(this.Detail)
          this.enddialogVisible = false // 成功之后关闭弹窗
          this.Getlists()               // 重新拉取列表
        } else {
        }
      })
    },

    // 请求
    // 地区联动
    async Areas() {
      const { data } = await Area()
      this.usetypelist = data.data.list
    },

    // 获取供应商类型
    async Types() {
      const { data } = await Type()
      this.trashtypeData = data.data
    },

    // 业务负责人
    async Drops(name, cd) {
      const { data } = await Drop({ name })
      // console.log(data.data)
      this.userlist = data.data
      cd(data.data)
    },
    // 获取列表
    async Getlists() {
      const { data } = await Getlist(this.pageData)
      // console.log(data);
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.getanpostfun(data, '获取')
    },
    // 添加客户信息
    async Addsuppliers(v) {
      let ss = JSON.parse(JSON.stringify(v))
      ss.type=JSON.stringify(ss.type)
      const { data } = await Addsupplier(ss)
      // console.log(data)
      this.getanpostfun(data, '添加',this.Getlists(),this.handleClose())
    },

    // 拉取供应商详情信息
    async Detailsgongs(supplier_id) {
      const {
        data: { data: res }
      } = await Detailsgong({ supplier_id })
      // console.log(res)
      this.Detail.supplier = res.info.supplier
      this.Detail.simple_name = res.info.simple_name
      this.Detail.type = res.type_info
      this.Detail.linkman = res.info.linkman
      this.Detail.area = res.info.area
      this.Detail.contact = res.info.contact
      this.Detail.contact_address = res.info.contact_address
      this.Detail.contact_phone = res.info.contact_phone
      this.Detail.principal_user = res.user_info.id
      this.Detail.principal_users = res.user_info.name
      this.Detail.billing_bank = res.info.billing_bank
      this.Detail.billing_cardno = res.info.billing_cardno
      this.Detail.tax_no = res.info.tax_no
    },

    // 修改供货商信息
    async EndCompiles(v) {
      // console.log(v);
      let ss = JSON.parse(JSON.stringify(v))
      ss.type=JSON.stringify(ss.type)
      const { data } = await EndCompile(ss)
      this.getanpostfun(data, '修改')
    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style scoped>
</style>