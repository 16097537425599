import axios from '@/api/index'
// 获取客户类型下拉
export const Drop = data => {
    return axios.request({
      url: '/customer/types',
      method: 'get',
      data
    })
  }
// 获取列表
  export const Getlist = params => {
    return axios.request({
      url: `/customer/list`,
      method: 'get',
      params
    })
  }

// // 关键字获取区县
//   export const Region = params => {
//     return axios.request({
//       url: `/area/towns`,
//       method: 'get',
//       params 
//     })
//   }

  // 添加数据
  export const ClientInformation = data => {
    return axios.request({
      url: '/customer/create',
      method: 'post',
      data,
    })
  }

  // 获取用户详情信息
  export const Customersdata = params => {
    return axios.request({
      url: `/customer/info?`,
      method: 'get',
      params 
    })
  }


    // 客户修改
    export const Compile = data => {
      return axios.request({
        url: `/customer/edit`,
        method: 'post',
        data 
      })
    }

      // 获取地区
  export const Area = params => {
    return axios.request({
      url: `/area/trees`,
      method: 'get',
      params 
    })
  }

  // 业务负责人
export const Fuzeren = params => {
  return axios.request({
    url: 'supplier/users',
    method: 'get',
    params
  })
}











